import queryToString from "./apiClient/queryToString";
import {useRoute} from "vue-router";


export default class ApiClient {

    constructor($emitter, baseUrl, accessKey, testing = false) {
        this._$emitter = $emitter;
        this._baseUrl = baseUrl;
        this._accessKey = accessKey;
        this._easyLizeApiTokenTtl = 21600; //6 Stunden
        this._easyLizeApiTokenVersion = '202211071116';
        this._token = this._getEasyLizeApiToken();
        this._request = null;
        this._context = null;
        this._languageId = localStorage.getItem('easyLizeLanguageId') || null
        this._testing = testing === true;
        this._whitelabelKey = '';
    }

    _deleteEasyLizeApiToken() {
        localStorage.removeItem('easyLizeApiToken');
        this._token = null;
    }

    _getEasyLizeApiToken() {
        let easyLizeApiTokenStr = localStorage.getItem('easyLizeApiToken') || null;
        if(!easyLizeApiTokenStr) {
            return null;
        }
        try {
            const easyLizeApiToken = JSON.parse(easyLizeApiTokenStr);
            const now = new Date();
            /*console.log('getToken', {
                now: now.getTime(),
                token: easyLizeApiToken,
                isExpires: now.getTime() > easyLizeApiToken.expiry,
                expireIn: (easyLizeApiToken.expiry - now.getTime()) / 1000
            });*/
            if(now.getTime() > easyLizeApiToken.expiry || (easyLizeApiToken.version || '') != this._easyLizeApiTokenVersion) {
                this._deleteEasyLizeApiToken();
                this._$emitter.emit('easyLizeApiToken:expired');
                return null;
            }
            return easyLizeApiToken.value;
        } catch(e) { console.error(e); }
        return null;
    }

    _setEasyLizeApiToken(easyLizeApiToken) {
        const now = new Date()
        localStorage.setItem('easyLizeApiToken', JSON.stringify({
            expiry: now.getTime() + (this._easyLizeApiTokenTtl * 1000),
            version: this._easyLizeApiTokenVersion,
            value: easyLizeApiToken
        }));
    }

    _refreshEasyLizeApiToken() {
        const easyLizeApiToken = this._getEasyLizeApiToken();
        if(easyLizeApiToken) {
            this._setEasyLizeApiToken(easyLizeApiToken);
        }
    }

    _createRequest(type, url, contentType) {
        this._request = new XMLHttpRequest();
        this._request.open(type, url);
        this._request.setRequestHeader('sw-access-key', this._accessKey);
        if(this._languageId) {
            this._request.setRequestHeader('sw-language-id', this._languageId);
        }

        if (contentType) {
            this._request.setRequestHeader('Content-type', contentType);
            this._request.setRequestHeader('Accept', contentType);
        }

        if(this._testing) {
            const testDate = localStorage.getItem('easyLizeTestDate') || null;
            if(testDate) {
                this._request.setRequestHeader('x-eko-debug-date', testDate);
            }
        }

        if(this._whitelabelKey) {
            this._request.setRequestHeader('eko-whitelabel-key', this._whitelabelKey);
        }

        return this._request;
    }

    _registerOnLoaded(request, callback) {
        if (!callback) {
            return;
        }
        let aborted = false;
        request.onabort = () => {
            aborted = true;
        };
        request.addEventListener('loadend', () => {
            if(request.status === 204) {
                callback({}, request);
                return;
            }
            let result = request.responseText;
            if(result === '' && aborted) {
                return;
            }
            try {
                result = JSON.parse(result);
            } catch(e) {
                this.logError('api-client-exception', result.errors || result);
                this._$emitter.emit('api-client-exception', {
                    exception: e,
                    result: result
                });
            }
            callback(result || request.responseText, request);
        });
    }

    _handleErrors(callback) {
        return (result, request) => {
            if(request.status === 500 || request.status === 400) {
                this.logError('api-client-error', {
                    url: request.responseURL || '',
                    errors: typeof result.errors !== "undefined" ? result.errors : result
                });
                this._$emitter.emit('api-client-error', {
                    errors: result && typeof result.errors !== "undefined" ? result.errors : {},
                    result: result,
                    request: request
                });
                callback(result, request, false);
            } else {
                callback(result, request, true);
            }
        };
    }

    _sendRequest(request, data, callback, needToken = true) {
        this._registerOnLoaded(request, callback);
        if(this._token && needToken) {
            this._refreshEasyLizeApiToken();
            request.setRequestHeader('sw-context-token', this._token);
        }
        if(typeof data === 'object' && !(data instanceof FormData)) {
            data = JSON.stringify(data);
        }
        request.send(data);
        return request;
    }

    _getContentType(data, contentType) {
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
        if (data instanceof FormData) {
            contentType = false;
        }

        return contentType;
    }

    _fetchToken(callback) {
        this.getRequest('easylize/system/context', {}, (context, request) => {
            this._updateToken(context.token);
            this._context = context;
            callback(context, request);
        }, 'application/json', false);
    }

    _fetchContext(callback) {
        return this.getRequest('easylize/system/context', {}, (context, request) => {
            this._context = context;
            this._triggerContextChangedEvent(context);
            callback(context, request);
        });
    }

    _triggerContextChangedEvent(context) {
        this._$emitter.emit('contextChanged', context);
    }

    _updateToken(token) {
        this._token = token;
        this._setEasyLizeApiToken(token);
    }

    logError(type, content) {
        console.error(type, content);
        try {
            this.postRequest('easylize/system/log/error', {
                type: type,
                content: content,
                userAgent: window.navigator.userAgent || ''
            })
        } catch(e) {
            console.error(e);
        }
    }

    getRequest(endpoint, queryParams, callback, contentType = 'application/json', needToken = true) {
        const queryString = queryToString(queryParams);
        const url = this._baseUrl + '/store-api/' + endpoint + (queryString.length ? '?' + queryString : '');
        const request = this._createRequest('GET', url, contentType);

        if(needToken && !this._token) {
            this._fetchToken(() => {
                this._sendRequest(request, null, callback, needToken);
            })
            return request;
        }

        return this._sendRequest(request, null, callback, needToken);
    }

    postRequest(endpoint, data, callback, contentType = 'application/json', needToken = true) {
        const url = this._baseUrl + '/store-api/' + endpoint;
        contentType = this._getContentType(data, contentType);

        const request = this._createRequest('POST', url, contentType);

        if(needToken && !this._token) {
            this._fetchToken(() => {
                this._sendRequest(request, data, callback, needToken);
            })
            return request;
        }

        return this._sendRequest(request, data, callback, needToken);
    }

    patchRequest(endpoint, data, callback, contentType = 'application/json', needToken = true) {
        const url = this._baseUrl + '/store-api/' + endpoint;
        contentType = this._getContentType(data, contentType);

        const request = this._createRequest('PATCH', url, contentType);

        if(needToken && !this._token) {
            this._fetchToken(() => {
                this._sendRequest(request, data, callback, needToken);
            })
            return request;
        }

        return this._sendRequest(request, data, callback, needToken);
    }

    setLanguageId(languageId) {
        this._languageId = languageId;
    }

    setWhitelabelKey(whitelabelKey) {
        this._whitelabelKey = whitelabelKey;
    }

    getToken(callback) {
        if(!this._token) {
            const that = this;
            this._fetchToken(() => {
                callback(that._token);
            })
            return;
        }
        callback(this._token);
    }

    getContext(callback) {
        if(this._context) {
            callback(this._context, null);
            return;
        }
        this._fetchContext(callback);
    }

    getLanguages(callback) {
        return this.getRequest('easylize/system/language', { }, this._handleErrors(callback));
    }

    getSalutations(callback) {
        return this.getRequest('easylize/system/salutation', { }, this._handleErrors(callback));
    }

    getCountries(callback) {
        return this.getRequest('easylize/system/country', { }, this._handleErrors(callback));
    }

    getContractYears(callback, getAll = false) {
        return this.getRequest('easylize/contract-year', {
            getAll: getAll ? 1 : 0
        }, this._handleErrors(callback));
    }

    changeLanguage(languageId, callback) {
        return this.patchRequest('easylize/customer/language', { languageId }, this._handleErrors(callback));
    }

    login(callback, login, password) {
        this._deleteEasyLizeApiToken();
        return this.postRequest('easylize/customer/login', {
            login: login,
            password: password
        }, this._handleErrors((result, request) => {
            if(request.status === 200) {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ success: true, context: context }, request);
                });
            } else {
                callback({
                    success: false,
                    result: result,
                    errors: result.errors || []
                }, request);
            }
        }), 'application/json', false);
    }

    backofficeLogin(callback, id, token) {
        this._deleteEasyLizeApiToken();
        return this.postRequest(`eko-punkt-go/customer/${id}/backoffice-login`, {
            token: token
        },
        this._handleErrors((result, request) => {
            if(request.status === 200) {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ success: true, context: context }, request);
                });
            } else {
                callback({
                    success: false,
                    result: result,
                    errors: result.errors || []
                }, request);
            }
        }));
    }

    hashLogin(callback, id, hash) {
        this._deleteEasyLizeApiToken();
        return this.postRequest(`easylize/customer/${id}/login/${hash}`, {},
            this._handleErrors((result, request) => {
            if(request.status === 200) {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ success: true, context: context }, request);
                });
            } else {
                callback({
                    success: false,
                    result: result,
                    errors: result.errors || []
                }, request);
            }
        }));
    }

    logout(callback) {
        return this.postRequest('easylize/customer/logout', {}, this._handleErrors((result) => {
            this._deleteEasyLizeApiToken();
            this._fetchContext((context, request) => {
                this._triggerContextChangedEvent(context);
                callback({ success: true, context: context }, request);
            });
        }));
    }

    createCustomer(callback, customer) {
        return this.postRequest('easylize/customer/create', customer, this._handleErrors((result) => {
            if(result.duplicateLucidNumber && result.duplicateLucidNumber === true) {
                callback({ success: false, code: 'duplicateLucidNumber' });
            } else {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ success: true, context: context }, request);
                });
            }
        }));
    }

    updateCustomer(callback, customer) {
        return this.postRequest('easylize/customer/update', customer, this._handleErrors(callback));
    }

    updateCustomerEmail(callback, password, email, email2) {
        return this.postRequest('eko-punkt-go/customer/email', {
            password: password,
            email: email,
            email2: email2
        }, this._handleErrors(callback));
    }

    updateCustomerPassword(callback, oldPassword, password) {
        return this.postRequest('eko-punkt-go/customer/password', {
            password: oldPassword,
            newPassword: password,
            newPassword2: password
        }, this._handleErrors(callback));
    }

    getProducts(callback, contractYear, contractPeriodId = null, quantityType = 'new') {
        return this.getRequest('easylize/products', {
            contractPeriodId: contractPeriodId,
            contractYear: contractYear,
            quantityType: quantityType
        }, this._handleErrors(callback));
    }

    validateContractYear(callback, contractYear) {
        return this.getRequest('easylize/contract-year/' + contractYear + '/validate', {}, (result) => {
            if(!result.success && result.contractYear) {
                localStorage.setItem('currentContractYear', result.contractYear);
            }
            this._$emitter.emit('contract-year-invalid', { error: result.error, contractYear: contractYear });
            callback(result, contractYear);
        });
    }

    changeCustomerGroup(callback, activationCode, contractYear) {
        this.postRequest('easylize/customer-group/activate', {
            activationCode: activationCode
        }, this._handleErrors((result, request) => {
            if(request.status !== 404 && request.status !== 500) {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this.validateContractYear((result) => {
                        this._triggerContextChangedEvent(context);
                        if(result.success) {
                            this._$emitter.emit('customer-group-changed', context.customerGroup);
                            callback({ success: true, context: context }, request);
                        }
                    }, contractYear);
                });
            } else {
                callback({ success: false }, request);
            }
        }));
    }

    activateWhitelabel(callback, activationCode) {
        this.postRequest('easylize/whitelabel/activate', {
            activationCode: activationCode
        }, this._handleErrors((result, request) => {
            if(request.status !== 404 && request.status !== 500) {
                this.setWhitelabelKey(activationCode);
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ success: true, context: context }, request);
                });
            } else {
                callback({ success: false }, request);
            }
        }));
    }

    getCart(callback) {
        return this.getRequest('easylize/cart', {}, this._handleErrors(callback));
    }

    clearCart(callback) {
        return this.postRequest('easylize/cart/clear', {}, this._handleErrors(callback));
    }

    recalculateCart(callback) {
        return this.postRequest('easylize/cart/recalculate', {}, this._handleErrors(callback));
    }

    calculateCart(callback, productQuantities, contractPeriodId = null, contractYear = null, quantityType = 'new') {
        return this.postRequest('easylize/cart/calculate', {
            productQuantities: productQuantities,
            contractPeriodId: contractPeriodId,
            contractYear: contractYear,
            quantityType: quantityType
        }, this._handleErrors(callback));
    }

    showMaxOrderVolumenModal() {
        return this.postRequest('easylize/cart/show-max-order-volumen-popup');
    }

    updateCustomerGroup(callback) {
        return this.postRequest('easylize/cart/change-customer-group', {}, this._handleErrors((result, request) => {
            if(result.changed) {
                this._updateToken(result.contextToken);
                this._fetchContext((context, request) => {
                    this._triggerContextChangedEvent(context);
                    callback({ changed: true, context: context }, request);
                });
            } else {
                callback({ changed: false }, request);
            }
        }));
    }

    applyCartVoucher(callback, voucherCode) {
        return this.postRequest('easylize/cart/applyVoucher/' + voucherCode, {}, this._handleErrors((cart) => {
            const promotionNotFoundError = cart.errors['promotion-not-found'] || cart.errors['promotion-not-eligible'] || null;
            if(promotionNotFoundError) {
                callback({
                    cart: cart,
                    success: false,
                    code: 'promotion-not-found'
                });
            } else {
                callback({
                    cart: cart,
                    success: true
                });
            }
        }));
    }

    removeCartVoucher(callback, itemId) {
        return this.postRequest('easylize/cart/removeVoucher/' + itemId, {}, this._handleErrors(callback));
    }

    getPaymentMethods(callback, contractYear = null, contractPeriodId = null) {
        return this.getRequest('easylize/payment/payment-methods', {
            contractYear: contractYear,
            contractPeriodId: contractPeriodId
        }, this._handleErrors(callback));
    }

    validateNewContract(callback, contractYear, cart, paymentMethodId, invoiceInterval = null) {
        return this.postRequest('easylize/contract/new', {
            contractYear: contractYear,
            co2Bilanz: cart.calculateCo2Bilanz(),
            productQuantities: cart.getInputWeights(),
            paymentMethodId: paymentMethodId,
            invoiceInterval: invoiceInterval,
            validate: true,
            storefrontUrl: window.location.origin || ''
        }, this._handleErrors((result, request) => {
            if(!result.contextToken) {
                callback(result, request);
                return;
            }
            this._updateToken(result.contextToken);
            this._fetchContext((_, request) => {
                callback(result, request);
            });
        }));
    }

    createNewContract(callback, contractYear, cart, paymentMethodId, invoiceInterval = null, paymentOptions = {}, options = {}) {
        return this.postRequest('easylize/contract/new', {
            contractYear: contractYear,
            co2Bilanz: cart.calculateCo2Bilanz(),
            productQuantities: cart.getInputWeights(),
            paymentMethodId: paymentMethodId,
            invoiceInterval: invoiceInterval,
            paymentOptions: paymentOptions,
            options: options,
            storefrontUrl: window.location.origin || ''
        }, this._handleErrors((result, request) => {
            if(!result.contextToken) {
                callback(result, request);
                return;
            }
            this._updateToken(result.contextToken);
            this._fetchContext((_, request) => {
                callback(result, request);
            });
        }));
    }

    contractPeriodQuantityPayment(callback, id, paymentMethodId, invoiceInterval = null, paymentOptions = {}) {
        return this.postRequest(`easylize/contract-period-quantity/${id}/pay`, {
            paymentMethodId: paymentMethodId,
            invoiceInterval: invoiceInterval,
            paymentOptions: paymentOptions
        }, this._handleErrors(callback));
    }

    sendContractPeriodQuantityDocuments(callback, id) {
        return this.postRequest(`easylize/contract-period-quantity/${id}/send-documents`, {}, this._handleErrors(callback));
    }

    getPaymentResult(callback, contractId, contractPeriodQuantityId) {
        return this.getRequest(`easylize/payment/${contractId}/${contractPeriodQuantityId}/result`, {}, this._handleErrors(callback));
    }

    registerOptin(callback, hash, em, password) {
        this._deleteEasyLizeApiToken();
        return this.postRequest('easylize/customer-register/optin', {
            hash: hash,
            em: em,
            password: password
        }, this._handleErrors((result) => {
            this._updateToken(result.contextToken);
            this._fetchContext((context, request) => {
                this._triggerContextChangedEvent(context);
                callback(result, request);
            });
        }));
    }

    getContractPeriodList(callback) {
        return this.getRequest('easylize/contract-period/list', {}, this._handleErrors(callback));
    }

    getContractDetailList(callback) {
        return this.getRequest('easylize/contract/detail-list', {}, this._handleErrors(callback));
    }

    getContractPeriod(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}`, {}, this._handleErrors(callback));
    }

    showFinalAddendumInfo(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/show-final-addendum-info`, {}, this._handleErrors(callback));
    }

    getContractPeriodStats(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/stats`, {}, this._handleErrors(callback));
    }

    getContractContractYearPeriod(callback, contractId, contractYear) {
        return this.getRequest(`easylize/contract/${contractId}/${contractYear}`, {}, this._handleErrors(callback));
    }

    getContractPeriodQuantities(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/quantities`, {}, this._handleErrors(callback));
    }

    getContractPeriodOrders(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/orders`, {}, this._handleErrors(callback));
    }

    getContractPeriodDocuments(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/documents`, {}, this._handleErrors(callback));
    }

    getContractPeriodActions(callback, id) {
        return this.getRequest(`easylize/contract-period/${id}/actions`, {}, this._handleErrors(callback));
    }

    createContractPeriodQuantity(callback, contractPeriodId, quantityType, cart, paymentMethodId, invoiceInterval = null, paymentOptions = {}, options = {}, forceZeroSubmit = false) {
        return this.postRequest(`easylize/contract-period/${contractPeriodId}/${quantityType}`, {
            co2Bilanz: cart.calculateCo2Bilanz(),
            productQuantities: cart.getInputWeights(),
            paymentMethodId: paymentMethodId,
            invoiceInterval: invoiceInterval,
            paymentOptions: paymentOptions,
            options: options,
            forceZeroSubmit
        }, this._handleErrors(callback));
    }

    getContractPeriodQuantity(callback, id) {
        return this.getRequest(`easylize/contract-period-quantity/${id}`, {}, this._handleErrors(callback));
    }

    createCartFromContractPeriodQuantity(callback, id) {
        return this.postRequest(`easylize/contract-period-quantity/${id}/cart`, {}, this._handleErrors(callback));
    }

    createCrefoPayOrder(callback) {
        return this.postRequest('easylize/payment/crefopay-payment/create-order', {}, this._handleErrors(callback));
    }

    createAdyenPayment(paymentMethodId, callback) {
        return this.postRequest('easylize/payment/adyen-payment/create', {
            paymentMethodId
        }, this._handleErrors(callback));
    }

    sendPasswordForgottenMail(callback, customerNumber, emailAddress) {
        return this.postRequest('easylize/customer/password/send-forgotten-mail', {
            customerNumber: customerNumber,
            emailAddress: emailAddress
        }, this._handleErrors(callback));
    }

    passwordRecover(callback, hash, password) {
        return this.postRequest('easylize/customer/password/recover', {
            hash: hash,
            password: password
        }, this._handleErrors(callback));
    }

    getContractYearParams(callback, contractYear, contractPeriodId) {
        return this.getRequest('easylize/contract-year-params', {
            contractYear: contractYear,
            contractPeriodId: contractPeriodId
        }, this._handleErrors(callback));
    }

    getCustomerMessages(callback, folder = 'inbox', limit = 5) {
        const criteria = {
            associations: {
                customer: [1]
            },
            filter: [
                {
                    type: 'equals',
                    field: 'customerFolder',
                    value: folder
                },
                {
                    type: 'equals',
                    field: 'deletedByCustomer',
                    value: false
                }
            ],
            sort: [
                {
                    field: 'mailDateTime',
                    order: 'DESC'
                }
            ]
        };
        if(limit > 0) {
            criteria.limit = limit;
        }
        return this.getRequest('eko-message-service/message', criteria, this._handleErrors(callback));
    }

    getCustomerInfo(callback, id, hash) {
        return this.getRequest(`easylize/customer-info/${id}/${hash}`, {}, this._handleErrors(callback));
    }

    getMessageHistory(callback, id) {
        return this.getRequest(`eko-message-service/message/${id}/history`, {}, this._handleErrors(callback));
    }

    sendMessage(callback, subject, message) {
        return this.postRequest('eko-message-service/message', {
            subject: subject,
            message: message
        }, this._handleErrors(callback));
    }

    replyMessage(callback, replyToId, subject, message) {
        return this.postRequest('eko-message-service/message', {
            replyToId: replyToId,
            subject: subject,
            message: message
        }, this._handleErrors(callback));
    }

    getNextContractCancellationDate(callback, contractId) {
        return this.getRequest(`easylize/contract/${contractId}/nextCancellationDateTime`, {}, this._handleErrors(callback));
    }

    cancelContract(callback, contractId, cancellationReason) {
        return this.postRequest(`easylize/contract/${contractId}/cancellation-request`, {
            cancellationReason: cancellationReason
        }, this._handleErrors(callback));
    }

    getWhitelabel() {
        const promise = new Promise((resolve) => {
            this.getRequest('easylize/whitelabel', {}, (whitelabel) => {
                resolve(whitelabel);
            });
        });
        return promise.then((whitelabel) => { return whitelabel; }).catch(() => { return { active: false }; });
    }

    handleAdyenPayment(paymentHandleUrl, data, callback) {
        const url = this._baseUrl + paymentHandleUrl;
        const contentType = this._getContentType(data, 'application/json');

        const request = this._createRequest('POST', url, contentType);
        return this._sendRequest(request, data, this._handleErrors(callback), false);
    }

    getAdyenPaymentStatus(paymentStatusUrl, orderId, callback) {
        const data = {
            orderId: orderId
        };
        const url = this._baseUrl + paymentStatusUrl;
        const contentType = this._getContentType(data, 'application/json');

        const request = this._createRequest('POST', url, contentType);
        return this._sendRequest(request, data, this._handleErrors(callback), false);
    }
}