<template>
  <div class="bg-red-circle">
    <div class="circle"></div>
  </div>
  <section id="step-5" class="easy-step" data-step="3">
    <div class="container-fluid container-xxl p-0">
      <div class="row g-0 position-relative">
        <div class="col-center">
<!--
          <div class="bg-red-circle">
            <div class="circle"></div>
          </div>
-->
          <cart ref="cart">
            <template #default="{ products, cart, params, isLoading, isZeroInput }">
              <loader-overlay :is-loading="isLoading" />
              <div class="col-12 easy-container mb-5" v-if="cart">
                <messages-container />
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <h4 class="my-4">{{ $t(`Unternehmen`) }}</h4>
                      <div class="row">

                        <div class="col-md-5">{{ $t(`Firma`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.company }}</div>

                        <div class="col-md-5">{{ $t(`LUCID Nr.`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.lucidNumber }}</div>

                        <div class="col-md-5">{{ $t(`Umsatzsteuer ID-Nr.`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.vatId }}</div>

                        <div class="col-md-5">{{ $t(`Steuer-Nr.`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.taxNumber }}</div>
  <hr class="my-4">
  <h4 class="my-4">{{ $t(`Adresse`) }}</h4>
                        <div class="col-md-5">{{ $t(`Kontaktadresse`) }}</div>
                        <div class="col-md-7">
                          {{ wizard.data.customer.companyAddress.company }}<br>
                          {{ wizard.data.customer.companyAddress.firstName }} {{ wizard.data.customer.companyAddress.lastName }} <br>
                          
                          <span v-if="wizard.data.customer.companyAddress.additionalAddressLine1"> {{ wizard.data.customer.companyAddress.additionalAddressLine1 }} <br></span> 
                          <span v-if="wizard.data.customer.companyAddress.additionalAddressLine2"> {{ wizard.data.customer.companyAddress.additionalAddressLine2 }} <br></span> 
                          {{ wizard.data.customer.companyAddress.street }}<br>
                          {{ wizard.data.customer.companyAddress.zipcode }} {{ wizard.data.customer.companyAddress.city }}<br>
                          {{ wizard.data.customer.companyAddress.country.name }}
                        </div>

                        <div v-if="wizard.data.customer.differentBillingAddress" class="col-md-5 mt-3">{{ $t(`Rechnungsadresse`) }}</div>
                        <div v-if="wizard.data.customer.differentBillingAddress" class="col-md-7 mt-3">
                          {{ wizard.data.customer.billingAddress.company }}<br>
                          {{ wizard.data.customer.billingAddress.firstName }} {{ wizard.data.customer.billingAddress.lastName }} <br>
                          <span v-if="wizard.data.customer.billingAddress.additionalAddressLine1"> {{ wizard.data.customer.billingAddress.additionalAddressLine1 }} <br></span> 
                          <span v-if="wizard.data.customer.billingAddress.additionalAddressLine2"> {{ wizard.data.customer.billingAddress.additionalAddressLine2 }} <br></span> 
                          {{ wizard.data.customer.billingAddress.street }}<br>
                          {{ wizard.data.customer.billingAddress.zipcode }} {{ wizard.data.customer.billingAddress.city }}<br>
                          {{ wizard.data.customer.billingAddress.country.name }}
                        </div>                        
  <hr class="my-4">
  <h4 class="my-4">{{ $t(`Kontakt`) }}</h4>

                        <div class="col-md-5">{{ $t(`Anrede`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.salutation.displayName }}</div>

                        <div class="col-md-5">{{ $t(`Vorname`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.firstName }}</div>

                        <div class="col-md-5">{{ $t(`Nachname`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.lastName }}</div>                        

                        <div class="col-md-5">{{ $t(`E-Mail`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.emailAddress }}</div>

                        <div class="col-md-5">{{ $t(`Telefon-Nr.`) }}</div>
                        <div class="col-md-7">{{ wizard.data.customer.phoneNumber }}</div>                        


                          
                      </div>
                    </div>

                    
                    <div class="col-12 col-lg-6">
                      <h4 class="my-4">{{ replacePlaceholders($t(`Jahresmenge Verpackungen {contractYear}`, { contractYear: wizard.data.lizenzjahr }), { contractYear: wizard.data.lizenzjahr }) }}</h4>

                      <template v-for="product in products">
                        <div class="row" v-if="product.inputWeight > 0.0 || isZeroInput() && product.type !== 'minimumValue'">
                          <div class="col-md-7">{{ $t(product.name) }}</div>
                          <div class="col-md-5">
                            <span class="input-weight">{{ $filters.formatNumber(product.inputWeight, 4) }} kg</span>
                            <span class="unit-price ms-2" v-if="params.addPeriodQuantityShowUnitPrice">({{ $filters.formatNumber(product.unitPrice, 4)}} &euro;/kg)</span>
                          </div>
                        </div>
                      </template>


                      <div class="row mt-3">
                        
                        <div class="col-md-7">CO<sub>2</sub> {{ $t(`Bilanz`) }}</div>                           
                        <div class="col-md-5">{{ $filters.formatNumber(wizard.data.co2Bilanz.total, 4) }} kg CO<sub>2</sub></div>

                      </div>


                      <div class="row mt-3">

                        <template v-for="voucherItem in getVoucherItems(cart)">
                          <div class="col-md-7">{{ $t(`Gutscheincode`) }}</div>
                          <div class="col-md-5">{{voucherItem.payload.code}}<br>{{ $filters.formatNumber(voucherItem.price.unitPrice) }} EUR</div>
                        </template>

                        <div class="col-md-7">{{ $t(`Lizenzjahr`) }}</div>
                        <div class="col-md-5">{{ wizard.data.lizenzjahr }}</div>   

                        <div class="col-md-7">{{ $t(`Nettopreis`) }}</div>
                        <div class="col-md-5">{{ $filters.formatNumber(cart.price.netPrice) }} EUR</div>    
                      </div>

                      <div class="row" v-for="tax in cart.price.calculatedTaxes">
                        <div class="col-md-7">{{ $t(`MwSt.`) }} {{ tax.taxRate }} %</div>
                        <div class="col-md-5">{{ $filters.formatNumber(tax.tax) }} EUR</div>
                      </div>     

                      <div class="row mt-3">
                        
                        <div class="col-md-7"><b>{{ $t(`Rechnungsbetrag`) }}</b></div>                           
                        <div class="col-md-5"><b>{{ $filters.formatNumber(cart.price.totalPrice) }} EUR</b></div>    

                      </div>



  <hr class="my-4">
  
                    <div class="row ">
                      <div class="col-12">
                        <payment-method-select :contract-year="wizard.data.lizenzjahr" v-model:payment-method-id="wizard.data.payment.paymentMethodId" v-model:invoice-interval="wizard.data.payment.invoiceInterval" ref="paymentmethodSelect" @validity-change="onPaymentmethodValidityChange">
                          <template v-slot:content-after="{ isLoading, hidePaymentMethods }">
                            <hr v-if="!isLoading && !hidePaymentMethods">
                          </template>
                        </payment-method-select>
                        <gtc-container ref="gtcContainer" id="gtc-container" :show-gtc-link="!wizard.data.customer.active" />

                        <div class="mt-3" v-if="showNewsletterCheckox || showKontaktaufnahmeCheckbox">

                          <div class="form-check" v-if="showNewsletterCheckox">
                            <input class="form-check-input" type="checkbox" :value="true" v-model="newsletterRegister" id="newsletterRegisterCheckbox" />
                            <label class="form-check-label" for="newsletterRegisterCheckbox">
                              {{ $t(`Ja, ich möchte den Newsletter EKO-PUNKT GmbH & Co. KG mit Informationen zu Leistungen, Produkten und Infos abonnieren.`) }} ({{ $t(`Abmeldung jederzeit möglich`) }})
                            </label>
                          </div>

                          <div class="form-check" v-if="showKontaktaufnahmeCheckbox">
                            <input class="form-check-input" type="checkbox" :value="true" v-model="kontaktaufnahme" id="kontaktaufnahmeCheckbox" />
                            <label class="form-check-label" for="kontaktaufnahmeCheckbox">
                              {{ $t(`Ja, ich möchte Informationen zu neuen Services und exklusiven Angeboten der EKO-PUNKT GmbH & Co. KG erhalten und erteile die Erlaubnis zur Kontaktaufnahme zu Werbezwecken.`) }} ({{ $t(`Abmeldung jederzeit möglich`) }})
                            </label>
                          </div>
                        </div>



                      </div>
                    </div>

              

                    </div>
                    

                        
                  </div>
                </div>
            

<!--   
                          
                          


                TODO: Step 5:<br><br>
                Daten:<br>
                <pre style="max-height: 500px">{{ wizard.data }}</pre>



                Produkte:<br>
                <pre style="max-height: 500px">{{ products }}</pre>
                Warenkorb:<br>
                <pre style="max-height: 500px">{{ cart }}</pre>


                <payment-method-select :contract-year="wizard.data.lizenzjahr" v-model:payment-method-id="wizard.data.payment.paymentMethodId" v-model:invoice-interval="wizard.data.payment.invoiceInterval" ref="paymentmethodSelect" />
                <hr>
                <gtc-container ref="gtcContainer" id="gtc-container" />

-->
             
              </div>
            </template>

            <template #cart-modal="{ params }">
              <zero-quantity-input-modal v-if="showZeroQuantityInputModal" :params="params" @closed="showZeroQuantityInputModal = false" @back-btn-clicked="forceZeroSubmit = false; $router.go(-1)" @submit-zero-quantity-input="onSubmitZeroQuantityInput" />
              <modal v-if="showContractCancelledModal" :auto-open="true" :show-close-btn="true" class="modal-lg zero-quantity-input-modal" ref="contractCancelledModal" @closed="showContractCancelledModal = false">
                <template #content>
                  <div class="alert alert-warning" role="alert">
                    <p v-html="$t(`Sehr geehrte Kundin, sehr geehrter Kunde,<br/>Ihr Vertragsstatus ist aktuell gekündigt, daher können wir Ihre Mengenmeldung leider nicht speichern und bestätigen.`)"></p>
                    <p>{{ $t(`Wenn Sie Ihre Kündigung zurückziehen möchten, um Ihre Mengenmeldung fortzusetzen, so klicken Sie bitte auf nachstehenden Button`) }}:</p>
                  </div>
                </template>
                <template #footer>
                  <div class="w-100 d-flex justify-content-between">
                    <button type="button" class="btn btn-primary" @click="onReactivateContract">
                      <slot name="submit-btn-text">&gt; {{ replacePlaceholders($t(`Hiermit ziehe ich die Kündigung zu meinem Vertrag mit der Kundennummer {customerNumber} zurück`, {
                        customerNumber: wizard.data.customer.customerNumber
                      }), {
                        customerNumber: wizard.data.customer.customerNumber
                      }) }}</slot>
                    </button>
                  </div>
                </template>
              </modal>
            </template>
          </cart>



          <div class="step-nav mt-5 pt-5">
            <!--<a class="btn btn-primary me-4">&lt; Zurück</a><a class="btn btn-primary">> Weiter</a>-->
            <button class="btn btn-primary me-4 demo-only" @click="wizard.goBack()">&lt; {{ $t(`zurück`) }}</button>
            <button class="btn btn-primary me-4 demo-only" id="btn-contract-submit" @click="onCreateContract" :disabled="isCreatingContract">
              <div class="spinner-border wizard-step4-loader" role="status" v-if="isCreatingContract">
                <span class="sr-only"></span>
              </div>
              <template v-if="wizard.data.customer.active">
                &gt; {{ $t(`Auftrag kostenpflichtig abschließen`) }}
              </template>
              <template v-if="!wizard.data.customer.active">
                &gt; {{ $t(`Vertrag abschließen`) }}
              </template>
            </button>
            <!-- div class="btn btn-primary demo-only" @click="wizard.showStep('step4')"> Weiter</div -->
          </div>
        </div>

        <wizard-circle-bar current-step="step5" :show-calculation-help="showCalculationHelp" />
      </div>
    </div>
    <modal v-if="showEmptyCartError" :auto-open="true" :show-close-btn="false" class="cart-empty-modal">
      <template v-slot:content>
        <div class="alert alert-danger" role="alert">
          {{ $t('Vertrag konnte nicht abgeschlossen werden. Der Warenkorb ist leer.') }}
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('Schließen') }}</button>
      </template>
    </modal>
  </section>
</template>

<script>
import WizardCircleBar from "./wizard-circle-bar";
import Cart from "../cart/cart";
import LoaderOverlay from "../utils/loader-overlay";
import PaymentMethodSelect from "../form/payment-method/payment-method-select";
import GtcContainer from "../form/checkout/gtc-container";
import Modal from "../modal/modal";
import Products from "../../pages/test/products.vue";
import MessagesContainer from "../utils/messages-container.vue";
import {replacePlaceholders, scrollTop} from "../../../utils/utils";
import ZeroQuantityInputModal from "../modal/zero-quantity-input-modal.vue";
export default {
  name: "wizard-step5",
  components: {
    ZeroQuantityInputModal,
    MessagesContainer,
    Products, Modal, GtcContainer, PaymentMethodSelect, LoaderOverlay, Cart, WizardCircleBar},
  props: {
    wizard: {
      type: Object,
      required: true
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isCreatingContract: false,
      showEmptyCartError: false,
      showNewsletterCheckox: true,
      newsletterRegister: false,
      showKontaktaufnahmeCheckbox: true,
      kontaktaufnahme: false,
      forceZeroSubmit: false,
      reactivateContract: false,
      showZeroQuantityInputModal: false,
      showContractCancelledModal: false,
      paymentMethodValid: true
    }
  },
  created() {
    scrollTop();
  },
  methods: {
    replacePlaceholders,
    onPaymentmethodValidityChange(result) {
      this.paymentMethodValid = result.success;
    },
    onCreateContract() {
      this.showEmptyCartError = false;
      if(this.$refs.cart.isEmpty()) {
        return;
      }
      this.$refs.gtcContainer.validate().then((gtcResult) => {
        if(!gtcResult.valid) {
          return;
        }
        easyLizeApp.apiClient.validateContractYear((result) => {
          this.isCreatingContract = true;
          if(!result.success) {
            this.isCreatingContract = false;
            return;
          }
          this._validateContract().then(() => {
            this.$refs.paymentmethodSelect.validate((paymentOptions, startPayment) => {
              this._createContract(paymentOptions).then((response) => {
                if(!response.success) {
                  console.error('contract creation failed', response);
                  return;
                }
                if(typeof startPayment == 'function') {
                  startPayment(response);
                } else {
                  window.location.replace(response.redirect);
                }
              });
            });
          }).catch(() => {
            this.isCreatingContract = false;
          });

        }, this.wizard.data.lizenzjahr);
      });
    },
    onSubmitZeroQuantityInput() {
      this.showZeroQuantityInputModal = false;
      this.forceZeroSubmit = true;
      this.onCreateContract();
    },
    onReactivateContract() {
      this.$refs.contractCancelledModal.hide();
      this.reactivateContract = true;
      this.onCreateContract();
    },
    getVoucherItems(cart) {
      if(!cart) {
        return [];
      }
      return cart.lineItems.filter((item) => {
        return item.type === 'promotion';
      });
    },
    _validateContract() {
      return new Promise((resolve, reject) => {
        easyLizeApp.apiClient.validateNewContract((response) => {
        if(response.success) {
          resolve();
          return;
        } else if(response.errorCode == 'cart.zero-submit') {
          this.isCreatingContract = false;
          this.showZeroQuantityInputModal = true;
        } else if(response.errorCode == 'contract.cancelled') {
          this.isCreatingContract = false;
          this.showContractCancelledModal = true;
        } else {
          this.showEmptyCartError = true;
          this.isCreatingContract = false;
        }
        reject();
      }, this.wizard.data.lizenzjahr, this.$refs.cart, this.wizard.data.payment.paymentMethodId, this.wizard.data.payment.invoiceInterval);
      });
      
    },
    _createContract(paymentOptions) {
      return new Promise((resolve, reject) => {
        easyLizeApp.apiClient.createNewContract((response) => {
          if(response.success) {
            resolve(response);
            return;
          } else if(response.errorCode == 'cart.zero-submit') {
            this.isCreatingContract = false;
            this.showZeroQuantityInputModal = true;
          } else if(response.errorCode == 'contract.cancelled') {
            this.isCreatingContract = false;
            this.showContractCancelledModal = true;
          } else {
            this.showEmptyCartError = true;
            this.isCreatingContract = false;
          }
          reject();
        }, this.wizard.data.lizenzjahr, this.$refs.cart, this.wizard.data.payment.paymentMethodId, this.wizard.data.payment.invoiceInterval, paymentOptions, {
            newsletterRegister: this.newsletterRegister,
            kontaktaufnahme: this.kontaktaufnahme,
            forceZeroSubmit: this.forceZeroSubmit,
            reactivateContract: this.reactivateContract
        });
      });
        
    }
  }
}
</script>
