<template>
    <div id="cards-container"></div>
  </template>
  
  <script>
  import AdyenCheckout from '@adyen/adyen-web';
  import '@adyen/adyen-web/dist/adyen.css';

  export default {
    name: "test-checkout",
    async created() {
      const configuration = {
        clientKey: 'test_6GXX3BON6RFCLH4233FWXIURVI55TOJZ',
        locale: "de_DE",
        environment: "test", // Change to 'live' for production
        showPayButton: true,
        //session: session,
        paymentMethodsConfiguration: {
          ideal: { showImage: true },
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            name: "Credit or debit card",
            amount: { value: 1000, currency: "EUR" },
          },
          paypal: {
            amount: { currency: "EUR", value: 1000 },
            environment: "test",
            countryCode: "DE", // Only needed for test
          },
        },
        onPaymentCompleted: (result, component) => {
          console.log("result: ", result);
          handleServerResponse(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
      };
      const checkout = await new AdyenCheckout(configuration);
      checkout.create('card').mount('#cards-container');
    }
    
  }
  </script>
  